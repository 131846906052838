import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS_SUPERADMIN = [
  { state: 'location', type: 'link', name: 'Branch Details', icon: 'vignette' },
  { state: 'admin', type: 'link', name: 'Admins', icon: 'assistant' },
  { state: 'stepper', type: 'link', name: 'All Students', icon: 'web' },
  { state: 'program', name: 'All Programs', type: 'link', icon: 'crop_7_5' },
  { state: 'reference', type: 'link', name: 'Reference', icon: 'av_timer' },
  { state: 'transaction', type: 'link', name: 'Daily Transactions', icon: 'assignment_turned_in' },
  { state: 'monthly-transaction', type: 'link', name: 'Monthly Transactions', icon: 'all_inclusive' },
  { state: 'certificate', type: 'link', name: 'Certificate Fees', icon: 'view_comfy' },
  { state: 'student-program', type: 'link', name: 'Program Upgrade', icon: 'vertical_align_center' },
  { state: 'student-info', type: 'link', name: 'Student Schedule', icon: 'tab' },
  { state: 'exam', type: 'link', name: 'Exam Details', icon: 'blur_circular' },
  { state: 'category', type: 'link', name: 'All Chapters', icon: 'vertical_align_center' },
  { state: 'question', type: 'link', name: 'Question bank', icon: 'web' },
  { state: 'question-paper', type: 'link', name: 'Question Papers', icon: 'all_inclusive' },
  { state: 'qualification', type: 'link', name: 'Qualification', icon: 'voicemail' },
  { state: 'student-enquiry', type: 'link', name: 'Enquiries', icon: 'web' },
  { state: 'banner', type: 'link', name: 'Banners', icon: 'adb' },
  { state: 'about', type: 'link', name: 'About', icon: 'border_horizontal' },
  { state: 'team', type: 'link', name: 'Team Members', icon: 'blur_circular' },
  { state: 'testinomial', type: 'link', name: 'Testinomial', icon: 'developer_mode' }
];

const MENUITEMS_ADMIN = [
  { state: 'stepper', type: 'link', name: 'Students', icon: 'web' },
  { state: 'certificate', type: 'link', name: 'Certificate Fees', icon: 'view_comfy' },
];

/* const MENUITEMS = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { state: 'button', type: 'link', name: 'Buttons', icon: 'crop_7_5' },
  { state: 'grid', type: 'link', name: 'Grid List', icon: 'view_comfy' },
  { state: 'lists', type: 'link', name: 'Lists', icon: 'view_list' },
  { state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
  { state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
  { state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
  {
    state: 'expansion',
    type: 'link',
    name: 'Expansion Panel',
    icon: 'vertical_align_center'
  },
  { state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
  { state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
  {
    state: 'progress-snipper',
    type: 'link',
    name: 'Progress snipper',
    icon: 'border_horizontal'
  },
  {
    state: 'progress',
    type: 'link',
    name: 'Progress Bar',
    icon: 'blur_circular'
  },
  {
    state: 'dialog',
    type: 'link',
    name: 'Dialog',
    icon: 'assignment_turned_in'
  },
  { state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
  { state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
  { state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
  {
    state: 'slide-toggle',
    type: 'link',
    name: 'Slide Toggle',
    icon: 'all_inclusive'
  }
]; */

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    let userInfo = sessionStorage.getItem("userInfo");
    if(userInfo)
      if(JSON.parse(userInfo).user_type == 1) return MENUITEMS_SUPERADMIN; else return MENUITEMS_ADMIN;
    else return MENUITEMS_SUPERADMIN;
  }
}
