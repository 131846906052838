import { MediaMatcher } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import {Event, RouterEvent, Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';


/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  checkoutUrls = ['/dashboard'];
  currentUrl: string = "";
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private router: Router
  ) {
   this.currentUrl = this.router.url

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}

  isCheckoutRoute() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  )
      .subscribe((event: any) => {
          this.currentUrl = event.url;
      });
    //this.changeDetectorRef.detectChanges();
    //console.log("this.currentUrl ", this.currentUrl)
		if (!this.currentUrl) {
			return false;
		}
		const index = this.checkoutUrls.includes(this.currentUrl);
		if (index) {
			return true;
		} else {
			return false;
		}
	}
}
