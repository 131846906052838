import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {AppService} from './../../../material-component/app.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {


  constructor(public appService: AppService, public router: Router, public cd: ChangeDetectorRef) {}

  signOut() {
    this.appService.logout({
      token: sessionStorage.getItem('auth')
    }).subscribe((data: any) => {
    sessionStorage.clear();
    this.cd.detectChanges();
    this.router.navigate(['/dashboard']);
  })
}
}
