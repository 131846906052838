import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + sessionStorage.getItem('auth'), 
  })
};

@Injectable({
  providedIn: "root"
})
export class AppService {

  constructor(private http: HttpClient) { }

  //rootURL = 'http://127.0.0.1:8000/api';
  rootURL = 'https://itlit.in/api';

  login(data: any) {
    return this.http.post(this.rootURL + '/login', data);
  }

  logout(data: any) {
    return this.http.post(this.rootURL + '/logout', data);
  }

  //Program
  saveProgram(data: any) {
    return this.http.post(this.rootURL + '/save/program', data, httpOptions);
  }

  getPrograms() {
    return this.http.get(this.rootURL + '/get/program', httpOptions);
  }

  getProgramById(id: number) {
    return this.http.get(this.rootURL + '/getById/program/' + id, httpOptions);
  }

  changeStatusProgram(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/program/' + id + '/' + status, httpOptions);
  }

  updateProgram(data: any) {
    return this.http.post(this.rootURL + '/update/program', data, httpOptions);
  }

  deleteProgram(data: any) {
    return this.http.post(this.rootURL + '/delete/program', data, httpOptions);
  }

  getActivePrograms() {
    return this.http.get(this.rootURL + '/get/active/program', httpOptions);
  }

//Qualification
  saveQualification(data: any) {
    return this.http.post(this.rootURL + '/save/qualification', data, httpOptions);
  }

  getQualification() {
    return this.http.get(this.rootURL + '/get/qualification', httpOptions);
  }

  getQualificationById(id: number) {
    return this.http.get(this.rootURL + '/getById/qualification/' + id, httpOptions);
  }

  changeStatusQualification(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/qualification/' + id + '/' + status, httpOptions);
  }

  updateQualification(data: any) {
    return this.http.post(this.rootURL + '/update/qualification', data, httpOptions);
  }

  deleteQualification(data: any) {
    return this.http.post(this.rootURL + '/delete/qualification', data, httpOptions);
  }

  getActiveQualifications() {
    return this.http.get(this.rootURL + '/get/active/qualification', httpOptions);
  }

  //Location
  saveLocation(data: any) {
    return this.http.post(this.rootURL + '/save/location', data, httpOptions);
  }

  getLocations() {
    return this.http.get(this.rootURL + '/get/location', httpOptions);
  }

  getLocationById(id: number) {
    return this.http.get(this.rootURL + '/getById/location/' + id, httpOptions);
  }

  changeStatusLocation(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/location/' + id + '/' + status, httpOptions);
  }

  updateLocation(data: any) {
    return this.http.post(this.rootURL + '/update/location', data, httpOptions);
  }

  deleteLocation(data: any) {
    return this.http.post(this.rootURL + '/delete/location', data, httpOptions);
  }

  getActiveLocations() {
    return this.http.get(this.rootURL + '/get/location/admin', httpOptions);
  }
  getLocationAdmin() {
    return this.http.get(this.rootURL + '/get/active/location', httpOptions);
  }

  assignLocation(data: any) {
    return this.http.post(this.rootURL + '/assign/location', data, httpOptions);
  }

  register(data: any) {
    return this.http.post(this.rootURL + '/register', data, httpOptions);
  }

  fileUpload(data: FormData) {
    return this.http.post(this.rootURL + '/upload/file', data, httpOptions);
  }

  saveStudent(data: any) {
    return this.http.post(this.rootURL + '/save/student', data, httpOptions);
  }

  getStudents() {
    return this.http.get(this.rootURL + '/get/student', httpOptions);
  }

  //Admin
  saveAdmin(data: any) {
    return this.http.post(this.rootURL + '/save/admin', data, httpOptions);
  }

  getAdmins() {
    return this.http.get(this.rootURL + '/get/admin', httpOptions);
  }

  getAdminById(id: number) {
    return this.http.get(this.rootURL + '/getById/admin/' + id, httpOptions);
  }

  changeStatusAdmin(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/admin/' + id + '/' + status, httpOptions);
  }

  updateAdmin(data: any) {
    return this.http.post(this.rootURL + '/update/admin', data, httpOptions);
  }

  saveApplication(data: any) {
    return this.http.post(this.rootURL + '/application/save', data, httpOptions);
  }

  getTransactionByApplicationId(applicationId: number) {
    return this.http.get(this.rootURL + '/get/transaction/' + applicationId, httpOptions);
  }

  getAllTransactionByApplicationId(applicationId: number) {
    return this.http.get(this.rootURL + '/all/transaction/' + applicationId, httpOptions);
  }

  resendLink(data: any) {
    return this.http.post(this.rootURL + '/resend/payment/link', data, httpOptions);
  }


  saveAbout(data: any) {
    return this.http.post(this.rootURL + '/save/about', data, httpOptions);
  }

  getAbouts() {
    return this.http.get(this.rootURL + '/get/about', httpOptions);
  }

  getAboutById(id: number) {
    return this.http.get(this.rootURL + '/getById/about/' + id, httpOptions);
  }

  updateAbout(data: any) {
    return this.http.post(this.rootURL + '/update/about', data, httpOptions);
  }

  deleteAbout(id: number) {
    return this.http.get(this.rootURL + '/delete/about/' + id, httpOptions);
  }


  saveBanner(data: any) {
    return this.http.post(this.rootURL + '/save/banner', data, httpOptions);
  }

  getBanners() {
    return this.http.get(this.rootURL + '/get/banner', httpOptions);
  }

  getBannerById(id: number) {
    return this.http.get(this.rootURL + '/getById/banner/' + id, httpOptions);
  }

  updateBanner(data: any) {
    return this.http.post(this.rootURL + '/update/banner', data, httpOptions);
  }

  deleteBanner(id: number) {
    return this.http.get(this.rootURL + '/delete/banner/' + id, httpOptions);
  }


  saveTeam(data: any) {
    return this.http.post(this.rootURL + '/save/team', data, httpOptions);
  }

  getTeams() {
    return this.http.get(this.rootURL + '/get/team', httpOptions);
  }

  getTeamById(id: number) {
    return this.http.get(this.rootURL + '/getById/team/' + id, httpOptions);
  }

  updateTeam(data: any) {
    return this.http.post(this.rootURL + '/update/team', data, httpOptions);
  }

  deleteTeam(id: number) {
    return this.http.get(this.rootURL + '/delete/team/' + id, httpOptions);
  }


  saveTestinomial(data: any) {
    return this.http.post(this.rootURL + '/save/testinomial', data, httpOptions);
  }

  getTestinomial() {
    return this.http.get(this.rootURL + '/get/testinomial', httpOptions);
  }

  getTestinomialById(id: number) {
    return this.http.get(this.rootURL + '/getById/testinomial/' + id, httpOptions);
  }

  updateTestinomial(data: any) {
    return this.http.post(this.rootURL + '/update/testinomial', data, httpOptions);
  }

  deleteTestinomial(id: number) {
    return this.http.get(this.rootURL + '/delete/testinomial/' + id, httpOptions);
  }

  markPaymentAsCompleted(data: any) {
    return this.http.post(this.rootURL + '/save/application', data, httpOptions);
  }

  getStudentById(id: number) {
    return this.http.get(this.rootURL + '/get/studentById/' + id, httpOptions);
  }

  updateStudent(data: any) {
    return this.http.post(this.rootURL + '/update/student', data, httpOptions);
  }

  getStudentWithTransactionById(id: number) {
    return this.http.get(this.rootURL + '/get/student/transaction/' + id, httpOptions);
  }

  getTransactions() {
    return this.http.get(this.rootURL + '/transaction/show', httpOptions);
  }

  getMonthlyTransactions() {
    return this.http.get(this.rootURL + '/transaction/user/show', httpOptions);
  }

  sendSMS(id: number) {
    return this.http.post(this.rootURL + '/send-sms-user/' + id, httpOptions);
  }

  getUsersForReference() {
    return this.http.get(this.rootURL + '/reference/data', httpOptions);
  }

  deductReferenceAmount(userId: Number) {
    return this.http.get(this.rootURL + '/save/reference/' + userId, httpOptions);
  }

  getUsersForCertificate() {
    return this.http.get(this.rootURL + '/get/user/certificate', httpOptions);
  }

  saveCertificate(data: any) {
    return this.http.post(this.rootURL + '/save/certificate', data, httpOptions);
  }

  changeStatusCertificate(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/certificate/' + id + '/' + status, httpOptions);
  }

  getCertificateByApplicationId(applicationId: number) {
    return this.http.get(this.rootURL + '/get/application/certificate/' + applicationId, httpOptions);
  }

  upgradeDegradeProgram(data: any) {
    return this.http.post(this.rootURL + '/program/upgrade/degrade', data, httpOptions);
  }

  getUsersPerLocation(location: any, programId: number, slot: number) {
    return this.http.get(this.rootURL + '/students/per/timing?location=' + location + '&programId=' + programId + '&slot=' + slot, httpOptions);
  }

  getUsersForPrintSchedule(location: any, programId: number) {
    return this.http.get(this.rootURL + '/print/students/timing?location=' + location + '&programId=' + programId, httpOptions);
  }

  // Exam Category

  saveCategory(data: any) {
    return this.http.post(this.rootURL + '/save/category', data, httpOptions);
  }

  getCategories() {
    return this.http.get(this.rootURL + '/get/all/category', httpOptions);
  }

  getCategoryById(id: number) {
    return this.http.get(this.rootURL + '/getById/category/' + id, httpOptions);
  }

  changeStatusCategory(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/change/category/' + id + '/' + status, httpOptions);
  }

  updateCategory(data: any) {
    return this.http.post(this.rootURL + '/update/category', data, httpOptions);
  }

  deleteCategory(data: any) {
    return this.http.post(this.rootURL + '/delete/category', data, httpOptions);
  }

  getActiveCategorys() {
    return this.http.get(this.rootURL + '/get/active/category', httpOptions);
  }

  //Question

  saveQuestion(data: any) {
    return this.http.post(this.rootURL + '/save/question', data, httpOptions);
  }

  getAllQuestion() {
    return this.http.get(this.rootURL + '/get/all/question', httpOptions);
  }

  getQuestionById(id: number) {
    return this.http.get(this.rootURL + '/getById/question/' + id, httpOptions);
  }

  changeStatusQuestion(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/change/question/' + id + '/' + status, httpOptions);
  }

  updateQuestion(data: any) {
    return this.http.post(this.rootURL + '/update/question', data, httpOptions);
  }

  deleteQuestion(data: any) {
    return this.http.post(this.rootURL + '/delete/question', data, httpOptions);
  }

  //Question Paper

  saveQuestionPaper(data: any) {
    return this.http.post(this.rootURL + '/save/questionPaper', data, httpOptions);
  }

  getAllQuestionPaper() {
    return this.http.get(this.rootURL + '/get/all/questionPaper', httpOptions);
  }

  getQuestionPaperById(id: number) {
    return this.http.get(this.rootURL + '/getById/questionPaper/' + id, httpOptions);
  }

  changeStatusQuestionPaper(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/change/questionPaper/' + id + '/' + status, httpOptions);
  }

  updateQuestionPaper(data: any) {
    return this.http.post(this.rootURL + '/update/questionPaper', data, httpOptions);
  }

  deleteQuestionPaper(data: any) {
    return this.http.post(this.rootURL + '/delete/questionPaper', data, httpOptions);
  }

  getExamResult(userId: number) {
    return this.http.get(this.rootURL + '/exam/result/' + userId, httpOptions);
  }

  getExamResultAdmin() {
    return this.http.get(this.rootURL + '/admin/exam/result', httpOptions);
  }

  savePraticalMarks(data: any) {
    return this.http.post(this.rootURL + '/save/pratical/marks', data, httpOptions);
  }

  changeStatusUserExam(id: number, status: number) {
    return this.http.get(this.rootURL + '/status/change/userExam/' + id + '/' + status, httpOptions);
  }

  deleteUserExam(id: number) {
    return this.http.get(this.rootURL + '/delete/change/userExam/' + id, httpOptions);
  }

  getUser() {
    return this.http.get(this.rootURL + '/search/users', httpOptions);
  }

  addDiscount(data: any) {
    return this.http.post(this.rootURL + '/save/discount', data, httpOptions);
  }

  deleteStudentRegistration(id: number) {
    return this.http.get(this.rootURL + '/delete/registration/' + id, httpOptions);
  }

  getStudentRegistration() {
    return this.http.get(this.rootURL + '/registration/info', httpOptions);
  }

  getMonthlyTransactionsWithFilter(value: any) {
    return this.http.post(this.rootURL + '/transaction/filter/show', value, httpOptions);
  }

  getUsersForCertificateWithFilter(value: any) {
    return this.http.post(this.rootURL + '/user/filter/certificate', value, httpOptions);
  }

  getUsersWithFilter(value: any) {
    return this.http.post(this.rootURL + '/user/filter/student', value, httpOptions);
  }

  getDailyTransactionWithFilter(value: any) {
    return this.http.post(this.rootURL + '/daily/transaction/filter', value, httpOptions);
  }

  getReferenceWithFilter(value: any) {
    return this.http.post(this.rootURL + '/user/filter/reference', value, httpOptions);
  }

}
