import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Router } from '@angular/router';
import {AppService} from './../../../material-component/app.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  name: any = ""; 

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public appService: AppService, public router: Router, public cd: ChangeDetectorRef
  ) {
    let userInfo = sessionStorage.getItem("userInfo");
    if(userInfo)
      this.name = JSON.parse(userInfo).first_name + " " + JSON.parse(userInfo).last_name
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  signOut() {
    this.appService.logout({
      token: sessionStorage.getItem('auth')
    }).subscribe((data: any) => {
      console.log("data :: ", data)
    sessionStorage.clear();
    this.cd.detectChanges();
    this.router.navigate(['/dashboard']);
  })
}
}
